body {
  background-image: url("./img/background_image.jpg");
  background-size: 100%;
}
.App {
  text-align: center;
  display: flex;
  width: 1200px;
  flex-wrap: wrap;
  margin: auto;
}

/* Image Card */
.imageCard {
  width: 120px;
  height: 80px;
}

.deckView {
  text-align: center;
}

.highCards,
.lowCards {
  margin: auto;
  margin-top: 20px;
  border-radius: 5px;
  padding: 2px;
  display: grid;
  grid-template-columns: repeat(12, 7%);
}

/* CSS */
.btnReset {
  margin: auto;
  margin-top: 10px;
  width: 20%;
  appearance: button;
  background-color: #1899d6;
  border: solid transparent;
  border-radius: 16px;
  border-width: 0 0 4px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-family: din-round, sans-serif;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 20px;
  outline: none;
  overflow: visible;
  padding: 13px 16px;
  text-align: center;
  text-transform: uppercase;
  touch-action: manipulation;
  transform: translateZ(0);
  transition: filter 0.2s;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
  white-space: nowrap;
}

.btnReset:after {
  background-clip: padding-box;
  background-color: #1cb0f6;
  border: solid transparent;
  border-radius: 16px;
  border-width: 0 0 4px;
  bottom: -4px;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

.btnReset9:main,
.btnReset:focus {
  user-select: auto;
}

.btnReset:hover:not(:disabled) {
  filter: brightness(1.1);
  -webkit-filter: brightness(1.1);
}

.btnReset:disabled {
  cursor: auto;
}
